import React, { useEffect } from 'react';

const SwaggerPage: React.FC = () => {
  useEffect(() => {
    // Stoplight Elements 스크립트 동적 로드
    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@stoplight/elements/web-components.min.js';
    script.async = true;
    document.body.appendChild(script);

    // 스타일시트 동적 로드
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://unpkg.com/@stoplight/elements/styles.min.css';
    document.head.appendChild(link);

    // 컴포넌트 언마운트 시 정리
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <elements-api
        apiDescriptionUrl="/api/swagger/v3/api-docs"
        router="hash"
        layout="sidebar"
      />
    </div>
  );
};

export default SwaggerPage;

// elements-api 커스텀 엘리먼트 타입 선언
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'elements-api': React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          apiDescriptionUrl: string;
          router: string;
          layout: string;
        },
        HTMLElement
      >;
    }
  }
}
const apiUrl = import.meta.env.VITE_API_SERVER_URL;

export interface Services {
    image: string;
    title: string;
    tags: string[];
    url: string;
}

export const requestGetServices = async (): Promise<Services[]> => {
    const response = await fetch(`${apiUrl}/services`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            credentials: 'include',
        },
    });
    const result = await response.json();
    if (!result.success) {
        return [];
    }
    return result.services;
};
import { useEffect } from 'react';
import { getJiraUrl } from '../../request/GetJiraUrl';
import { useNavigate } from 'react-router-dom';

export const JiraPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getJiraUrl().then((fetchedUrl: string) => {
      console.log(fetchedUrl);
      if (fetchedUrl === '') {
        alert('Jira URL을 불러오는데 실패했습니다.');
        navigate('/');
        return;
      }
      window.location.href = fetchedUrl;
    });
  }, [navigate]);

  return null;
};

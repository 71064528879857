import React, { useEffect, useState } from 'react';

interface HistoryItem {
  id: number;
  modifierEmail: string;
  modifiedAt: string;
  color: `#${string}`;
  environmentId?: number;
  environmentName?: string;
}

interface HistoryViewProps {
  history: HistoryItem[];
  isServiceView?: boolean;
  onHistoryItemClick?: (item: HistoryItem) => void;
  selectedHistoryItem?: HistoryItem | null;
  onRevertVersion?: (item: HistoryItem) => Promise<void>;
}

interface ContextMenuPosition {
  x: number;
  y: number;
  item: HistoryItem | null;
}

export const HistoryView: React.FC<HistoryViewProps> = ({ 
  history, 
  isServiceView = false,
  onHistoryItemClick,
  selectedHistoryItem,
  onRevertVersion
}) => {
  const [animationKey, setAnimationKey] = useState(0);
  const [contextMenu, setContextMenu] = useState<ContextMenuPosition>({ x: 0, y: 0, item: null });
  const [showRevertConfirm, setShowRevertConfirm] = useState(false);
  const [selectedRevertItem, setSelectedRevertItem] = useState<HistoryItem | null>(null);

  const sortedHistory = [...history].sort((a, b) => 
    new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
  );

  useEffect(() => {
    const handleClickOutside = () => {
      setContextMenu({ x: 0, y: 0, item: null });
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleContextMenu = (e: React.MouseEvent, item: HistoryItem, index: number) => {
    if (index === 0) return; // HEAD 버전은 우클릭 메뉴 비활성화
    
    e.preventDefault();
    setContextMenu({
      x: e.clientX,
      y: e.clientY,
      item
    });
  };

  const handleRevertClick = () => {
    if (contextMenu.item) {
      setSelectedRevertItem(contextMenu.item);
      setShowRevertConfirm(true);
      setContextMenu({ x: 0, y: 0, item: null });
    }
  };

  const handleRevertConfirm = async () => {
    if (selectedRevertItem && onRevertVersion) {
      await onRevertVersion(selectedRevertItem);
      setShowRevertConfirm(false);
      setSelectedRevertItem(null);
    }
  };

  // 환경별로 그룹화
  const groupedHistory = isServiceView
    ? sortedHistory.reduce((acc, item) => {
        const envId = item.environmentId || 0;
        if (!acc[envId]) {
          acc[envId] = {
            environmentName: item.environmentName || '기타',
            items: [item]
          };
        }
        return acc;
      }, {} as Record<number, { environmentName: string; items: HistoryItem[] }>)
    : { 0: { environmentName: '', items: sortedHistory } };

  useEffect(() => {
    setAnimationKey(prev => prev + 1);
  }, [history]);

  return (
    <div className="border-l xl:border-l border-gray-200 p-4 h-full">
      <style>
        {`
          @keyframes slideUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
          .history-item {
            animation: slideUp 0.15s ease-out forwards;
          }
          .latest-version {
            background-color: #c9ff4d;
            border-radius: 0.95rem;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
          }
          .head-label {
            background-color: #4F46E5;
            color: white;
            font-size: 0.75rem;
            padding: 0.25rem 0.5rem;
            border-radius: 0.75rem;
            font-weight: 500;
          }
          .history-item-clickable {
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            border-radius: 0.95rem;
            background-color: #f9fafb;
          }
          .history-item-clickable:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
            border-radius: 0.95rem;
          }
          .history-item-selected {
            background-color: #EEF2FF !important;
            border: 2px solid #4F46E5;
            border-radius: 0.95rem;
          }
          .context-menu {
            position: fixed;
            background: white;
            border: 1px solid #e5e7eb;
            border-radius: 0.5rem;
            padding: 0.5rem;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
            z-index: 50;
          }
          .context-menu-item {
            padding: 0.5rem 1rem;
            cursor: pointer;
            white-space: nowrap;
            transition: all 0.2s;
          }
          .context-menu-item:hover {
            background-color: #f3f4f6;
          }
        `}
      </style>
      <h3 className="text-lg font-semibold mb-4">수정 이력</h3>
      <div className="relative">
        {Object.entries(groupedHistory).map(([envId, { environmentName, items }]) => (
          <div key={envId} className="mb-6 last:mb-0">
            {isServiceView && environmentName && (
              <h4 className="text-sm font-medium text-gray-500 mb-3">{environmentName}</h4>
            )}
            {items.map((item, index) => (
              <div 
                key={`${animationKey}-${item.id}`}
                className={`relative mb-8 last:mb-0 history-item ${onHistoryItemClick ? 'history-item-clickable' : ''} ${
                  selectedHistoryItem?.id === item.id ? 'history-item-selected' : ''
                }`}
                style={{ 
                  animationDelay: `${index * 0.15}s`,
                  opacity: 0
                }}
                onClick={() => onHistoryItemClick?.(item)}
                onContextMenu={(e) => handleContextMenu(e, item, index)}
              >
                <div className={`flex items-center gap-3 relative z-10 p-3 rounded-lg ${
                  isServiceView || index === 0 ? 'latest-version' : 'history-item-clickable'
                }`}>
                  <div
                    className="w-8 h-8 rounded-full flex items-center justify-center text-white font-medium shrink-0"
                    style={{ backgroundColor: item.color as `#${string}` }}
                  >
                    {item.modifierEmail[0].toUpperCase()}
                  </div>
                  <div className="flex flex-col justify-center">
                    <p className="text-sm text-gray-600">{item.modifierEmail}</p>
                    <p className="text-xs text-gray-400">
                      {new Date(item.modifiedAt).toLocaleString()}
                    </p>
                  </div>
                  <div className="flex-1 flex justify-end">
                    {(isServiceView || index === 0) && (
                      <p className="head-label">HEAD</p>
                    )}
                  </div>
                </div>
                {index < items.length - 1 && (
                  <div className="absolute left-1/2 top-[4.0rem] -translate-x-[0.5px] w-0.5 h-9 bg-gray-300">
                    <div 
                      className="w-2 h-2 rotate-[225deg] border-r-2 border-b-2 border-gray-300 absolute left-1/2 top-1 transform -translate-x-1/2 -translate-y-1 bg-white"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}

        {/* Context Menu */}
        {contextMenu.item && (
          <div 
            className="context-menu"
            style={{ 
              left: `${contextMenu.x}px`, 
              top: `${contextMenu.y}px` 
            }}
          >
            <div 
              className="context-menu-item"
              onClick={handleRevertClick}
            >
              Revert to this version
            </div>
          </div>
        )}

        {/* Revert Confirmation Modal */}
        {showRevertConfirm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
            <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
              <h3 className="text-lg font-semibold mb-4">버전 복원 확인</h3>
              <p className="text-gray-600 mb-6">
                이 버전으로 돌아가시겠습니까?
              </p>
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setShowRevertConfirm(false)}
                  className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
                >
                  취소
                </button>
                <button
                  onClick={handleRevertConfirm}
                  className="px-4 py-2 bg-violet-500 text-white rounded-lg hover:bg-violet-600 transition-colors"
                >
                  확인
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}; 
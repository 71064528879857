const serverUrl = import.meta.env.VITE_API_SERVER_URL;

interface SetupOTPData {
    email: string;
}

interface SetupOTPResponse {
    success: boolean;
    qrCodeUrl: string;
    secretKey: string;
}

export const requestSetupOTP = async (data: SetupOTPData): Promise<SetupOTPResponse> => {
    const response = await fetch(`${serverUrl}/auth/setup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include"
    });
    const result = await response.json();
    return result;
};
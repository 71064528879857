const apiUrl = import.meta.env.VITE_API_SERVER_URL;

export interface MSAService {
  id: number;
  name: string;
  environments: Environment[];
  isExpanded: boolean;
}

export interface Environment {
  id: number;
  name: string;
  content: string;
}

export interface HistoryItem {
  id: number;
  modifierEmail: string;
  modifiedAt: string;
  color: `#${string}`;
  environmentId?: number;
  environmentName?: string;
}

export interface HistoryResponse {
  histories: HistoryItem[];
}

export interface VersionDiffResponse {
  previousContent: string,
  selectedContent: string
}

export interface HistoryContentResponse {
  content: string
}

// 서비스 목록 조회
export const getMSAServices = async (): Promise<MSAService[]> => {
  const response = await fetch(`${apiUrl}/configs/msa/services`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  
  // services 배열을 map
  return result.services.map((service: any) => ({
    ...service,
    isExpanded: false
  }));
};

// 서비스 추가
export const createMSAService = async (name: string): Promise<{ id: number; name: string }> => {
  const response = await fetch(`${apiUrl}/configs/msa/services`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  });
  const result = await response.json();
  
  // 프론트엔드에서 필요한 추가 필드를 여기서 설정
  return {
    ...result,
    subItems: [],
    isExpanded: false
  };
};

// 서비스 수정
export const updateMSAService = async (serviceId: number, name: string): Promise<MSAService> => {
  const response = await fetch(`${apiUrl}/configs/msa/services/${serviceId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  });
  return await response.json();
};

// 서비스 삭제
export const deleteMSAService = async (serviceId: number): Promise<boolean> => {
  const response = await fetch(`${apiUrl}/configs/msa/services/${serviceId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const result = await response.json();
  return result.success;
};

// 하위 항목 추가
export const createEnvironment = async (
  serviceId: number,
  name: string,
  content: string
): Promise<Environment> => {
  const response = await fetch(`${apiUrl}/configs/msa/services/${serviceId}/environments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name, content }),
  });
  return await response.json();
};

// 하위 항목 수정
export const updateEnvironment = async (
  serviceId: number,
  envId: number,
  name: string,
  content: string
): Promise<Environment> => {
  const response = await fetch(
    `${apiUrl}/configs/msa/services/${serviceId}/environments/${envId}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, content }),
    }
  );
  return await response.json();
};

// 하위 항목 삭제
export const deleteEnvironment = async (
  serviceId: number,
  envId: number
): Promise<boolean> => {
  const response = await fetch(
    `${apiUrl}/configs/msa/services/${serviceId}/environments/${envId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  const result = await response.json();
  return result.success;
};

// 하위 항목 내용 저장
export const updateEnvironmentContent = async (
  serviceId: number,
  envId: number,
  content: string
): Promise<Environment> => {
  const response = await fetch(
    `${apiUrl}/configs/msa/services/${serviceId}/environments/${envId}/content`,
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content }),
    }
  );
  return await response.json();
};

export const getServiceHistory = async (serviceId: number): Promise<HistoryItem[]> => {
  const response = await fetch(`${apiUrl}/configs/history/service/${serviceId}`);
  const data: HistoryResponse = await response.json();
  return data.histories;
};

export const getEnvironmentHistory = async (environmentId: number): Promise<HistoryItem[]> => {
  const response = await fetch(`${apiUrl}/configs/history/environment/${environmentId}`);
  const data: HistoryResponse = await response.json();
  return data.histories;
};

export const getVersionDiff = async (historyId : number): Promise<VersionDiffResponse> => {
  const response = await fetch(`${apiUrl}/configs/history/${historyId}/diff`);
  const data: VersionDiffResponse = await response.json();
  return data;
}

export const getVersionContent = async (historyId : number): Promise<HistoryContentResponse> => {
  const response = await fetch(`${apiUrl}/configs/history/${historyId}/content`);
  const data: HistoryContentResponse = await response.json();
  return data;
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import crevLogo from "../../assets/logo.webp";
import { requestVerifyOTP } from "../../request/VerifyOTP.ts";

export const QRAuth = ({ qrCodeUrl, email }: { qrCodeUrl: string; email: string }) => {
    const [code, setCode] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const response = await requestVerifyOTP({
                email: email,
                code: parseInt(code)
            });
            
            if (response.success) {
                navigate("/home");
            } else {
                alert("인증에 실패했습니다.");
                navigate("/login");
            }
        } catch (error) {
            alert("인증 과정에서 오류가 발생했습니다.");
            navigate("/login");
        }
    };

    return (
      <div className="min-h-screen w-full bg-white flex items-center justify-center p-4">
        <div className="w-full max-w-[400px] mx-auto">
          <div className="text-center mb-10">
            <img
              src={crevLogo}
              alt="Crev Logo"
              className="w-12 h-12 mx-auto mb-6"
            />
            <h1 className="text-2xl font-bold text-gray-900">2FA 인증</h1>
            <p className="text-gray-500 mt-2">
              1. Google Authenticator 앱을 설치합니다
            </p>
            <p className="text-gray-500 mt-2">2. QR 코드를 스캔합니다</p>
            <p className="text-gray-500 mt-2">3. 생성된 코드를 입력합니다</p>
          </div>
  
          <div className="bg-gray-50 border border-gray-200 rounded-xl p-8 mb-6">
            <div className="flex justify-center mb-4">
              <img
                src={qrCodeUrl}
                alt="QR Code"
                className="w-48 h-48 object-cover"
              />
            </div>
          </div>
  
          <div className="space-y-5">
            <div className="relative">
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="인증 코드 입력"
                maxLength={6}
              />
            </div>
  
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-full bg-purple-600 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors font-medium"
            >
              인증하기
            </button>
          </div>
        </div>
      </div>
    );
}
import { useState, useEffect } from 'react';
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { DraggableContentCard } from './DraggableContentCard';
import { requestGetServices, Services } from '../../request/GetServices.ts';
import crevLogo from "../../assets/logo.webp";
import { Save } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import {patchServiceOrder} from "../../request/PatchServiceOrder.ts";

export default function DraggableContentGrid() {
  const [services, setServices] = useState<Services[]>([]);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [positions, setPositions] = useState<number[]>([]);
  const navigate = useNavigate();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const fetchServices = async () => {
      const fetchedServices = await requestGetServices();
      setServices(fetchedServices);
      setPositions(fetchedServices.map((_, index) => index));
    };
    fetchServices();
  }, []);

  const handleDragStart = (event: any) => {
    setActiveIndex(event.active.id);
  };

  const handleDragOver = (event: any) => {
    const { active, over } = event;
    
    if (active.id !== over.id) {
      setPositions(positions => {
        const oldIndex = positions.indexOf(active.id);
        const newIndex = positions.indexOf(over.id);
        return arrayMove(positions, oldIndex, newIndex);
      });
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setServices(items => {
        const reorderedItems: Services[] = [];
        positions.forEach((pos) => {
          reorderedItems.push(items[pos]);
        });
        return reorderedItems;
      });
    }

    setActiveIndex(null);
  };

  const sortedServices = positions.map(pos => services[pos]);

  const handleSave = async () => {
    const orderRequests = sortedServices.map((service, index) => ({
      title: service.title,
      order: index
    }));
    const result = await patchServiceOrder(orderRequests);
    if (result) {
      alert('저장되었습니다.');
    } else {
      alert('저장에 실패하였습니다.');
    }
    navigate('/');
  };

  return (
    <main className="w-full min-h-screen bg-white pt-[72px]">
      <nav className="fixed top-0 left-0 right-0 w-full bg-white z-50 border-b border-gray-200">
        <div className="max-w-[3840px] mx-auto flex justify-between items-center py-4 px-8">
          <div className="flex items-center gap-2">
            <img src={crevLogo} alt="Crev Tools Logo" className="w-8 h-8" />
            <span className="text-xl font-semibold text-gray-800">
              Crev Tools
            </span>
          </div>
          <button
            onClick={handleSave}
            className="px-5 py-2.5 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-2 text-sm font-medium"
          >
            <Save className="w-4 h-4" />
            저장
          </button>
        </div>
      </nav>

      <div className="max-w-[3840px] mx-auto flex flex-col items-center w-full px-4 sm:px-6 lg:px-8 2xl:px-16">
        <h1 className="text-xl text-gray-600 mb-8 mt-8">
          ⚒️ 카드를 드래그하여 순서를 변경하세요 ⚒️
        </h1>

        <div className="w-full max-w-[3840px]">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragEnd={handleDragEnd}
          >
            <div className="w-full">
              <SortableContext items={positions}>
                <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 gap-4 md:gap-6 justify-items-center">
                  {sortedServices.map((service, index) => (
                    <DraggableContentCard
                      key={positions[index]}
                      index={positions[index]}
                      {...service}
                    />
                  ))}
                </div>
              </SortableContext>
            </div>

            <DragOverlay>
              {activeIndex !== null ? (
                <DraggableContentCard
                  index={activeIndex}
                  {...services[activeIndex]}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
        </div>
      </div>
    </main>
  );
}
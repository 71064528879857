import { X, SearchIcon, PlusIcon, SaveIcon, Edit, Download } from "lucide-react";
import { useEffect, useState, useRef } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import {
  getMSAServices,
  createMSAService,
  updateMSAService,
  deleteMSAService,
  createEnvironment,
  updateEnvironment,
  deleteEnvironment,
  updateEnvironmentContent,
  getServiceHistory,
  getEnvironmentHistory,
  type MSAService as Service,
  type HistoryItem,
  getVersionDiff,
  getVersionContent,
} from '../../request/MSAService';
import { Navbar } from "./Navbar";
import { ServiceItem } from "./ServiceItem";
import { Toast } from "./Toast";
import { HistoryView } from "./HistoryView";

interface DeleteConfirmation {
  show: boolean;
  type: 'service' | 'subItem' | null;
  id: number | null;
  serviceId: number | null;
}

interface ConfigEditorProps {
  isEditing: boolean;
  selectedSubItem: number;
  contentMap: Record<number, string>;
  handleTextChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  linePositions: number[];
}

const ConfigEditor: React.FC<ConfigEditorProps> = ({
  isEditing,
  selectedSubItem,
  contentMap,
  handleTextChange,
  linePositions,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div className="h-full flex">
      <div className="py-4 w-12 pr-2 text-right text-gray-400 select-none font-mono text-sm bg-gray-100 rounded-l-lg overflow-hidden">
        <div className="relative h-full">
          {linePositions.map((i) => (
            <div key={i} className="h-6 leading-6">
              {i + 1}
            </div>
          ))}
        </div>
      </div>
      <div className="relative flex-1 overflow-auto">
        <textarea
          ref={textareaRef}
          className={`w-full h-full py-4 px-4 border-0 border-l border-gray-200 rounded-r-lg resize-none focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all overflow-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400 font-mono text-sm ${
            !isEditing && "bg-gray-50 cursor-not-allowed"
          }`}
          placeholder="설정 내용을 입력하세요..."
          disabled={!isEditing}
          value={contentMap[selectedSubItem] || ""}
          onChange={handleTextChange}
          style={{ 
            lineHeight: '24px',
            minHeight: '24px',
            whiteSpace: 'pre',
            overflowWrap: 'normal',
          }}
          wrap="off"
        />
      </div>
    </div>
  );
};

interface DiffViewerProps {
  oldContent: string;
  newContent: string;
}

const DiffViewer: React.FC<DiffViewerProps> = ({ oldContent, newContent }) => {
  return (
    <div className="h-full overflow-auto bg-white">
      <style>
        {`
          .diff-viewer-wrapper {
            width: 100%;
            max-width: 400px;
            background-color: white;
          }
          .diff-viewer-wrapper pre {
            white-space: pre !important;
            word-wrap: normal !important;
            overflow-x: auto !important;
          }
          .diff-container {
            background-color: white !important;
            max-width: 100% !important;
          }
          .diff-line {
            max-width: 100% !important;
          }
        `}
      </style>
      <div className="diff-viewer-wrapper">
        <ReactDiffViewer
          oldValue={oldContent}
          newValue={newContent}
          splitView={false}
          useDarkTheme={false}
          hideLineNumbers={false}
        />
      </div>
    </div>
  );
};

export default function MSAConfig() {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedService, setSelectedService] = useState<number | null>(null);
  const [selectedSubItem, setSelectedSubItem] = useState<number | null>(null);
  const [editingSubItem, setEditingSubItem] = useState<number | null>(null);
  const [editedSubItemName, setEditedSubItemName] = useState<string>("");
  const [contentMap, setContentMap] = useState<Record<number, string>>({});
  const [editingService, setEditingService] = useState<number | null>(null);
  const [editedServiceName, setEditedServiceName] = useState<string>("");
  const [deleteConfirmation, setDeleteConfirmation] = useState<DeleteConfirmation>({
    show: false,
    type: null,
    id: null,
    serviceId: null,
  });
  const [services, setServices] = useState<Service[]>([]);
  const [activeSublistInput, setActiveSublistInput] = useState<number | null>(null);
  const [newSubItem, setNewSubItem] = useState("");
  const [newServiceName, setNewServiceName] = useState<string>("");
  const [showToast, setShowToast] = useState<boolean>(false);
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [isServiceView, setIsServiceView] = useState<boolean>(false);
  const [linePositions, setLinePositions] = useState<number[]>([]);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState<HistoryItem | null>(null);
  const [previousVersionContent, setPreviousVersionContent] = useState<string>("");
  const [selectedVersionContent, setSelectedVersionContent] = useState<string>("");
  const [showExtensionModal, setShowExtensionModal] = useState(false);
  const [fileExtension, setFileExtension] = useState("");

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const fetchedServices = await getMSAServices();
        setServices(prevServices => {
          return fetchedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
      } catch (error) {
        console.error('Failed to fetch services:', error);
      }
    };

    fetchServices();
  }, []);

  useEffect(() => {
    if (selectedService && selectedSubItem) {
      const service = services.find((s) => s.id === selectedService);
      const environment = service?.environments.find((e) => e.id === selectedSubItem);
      if (environment) {
        if (!contentMap[selectedSubItem]) {
          setContentMap(prev => ({
            ...prev,
            [selectedSubItem]: environment.content
          }));
        }
        setIsEditing(false);
      }
    }
  }, [selectedService, selectedSubItem, services]);

  const handleEditService = async (serviceId: number) => {
    const service = services.find((s) => s.id === serviceId);
    setEditingService(serviceId);
    setEditedServiceName(service?.name || '');
  };

  const saveServiceEdit = async (serviceId: number) => {
    if (editedServiceName.trim()) {
      try {
        await updateMSAService(serviceId, editedServiceName);
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
        setEditingService(null);
        setEditedServiceName('');
      } catch (error) {
        console.error('Failed to update service:', error);
      }
    }
  };

  const handleDeleteService = (serviceId: number) => {
    setDeleteConfirmation({
      show: true,
      type: 'service',
      id: serviceId,
      serviceId: null,
    });
  };

  const handleDeleteEnvironment = (serviceId: number, envId: number) => {
    setDeleteConfirmation({
      show: true,
      type: "subItem",
      id: envId,
      serviceId: serviceId,
    });
  };

  const confirmDelete = async () => {
    try {
      if (deleteConfirmation.type === 'service' && deleteConfirmation.id) {
        const success = await deleteMSAService(deleteConfirmation.id);
        if (success) {
          const updatedServices = await getMSAServices();
          setServices(prevServices => {
            return updatedServices.map(service => ({
              ...service,
              isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
              isNew: !prevServices.some(s => s.id === service.id)
            }));
          });
          if (selectedService === deleteConfirmation.id) {
            setSelectedService(null);
            setSelectedSubItem(null);
          }
        }
      } else if (
        deleteConfirmation.type === 'subItem' &&
        deleteConfirmation.id &&
        deleteConfirmation.serviceId
      ) {
        const success = await deleteEnvironment(
          deleteConfirmation.serviceId,
          deleteConfirmation.id
        );
        if (success) {
          const updatedServices = await getMSAServices();
          setServices(prevServices => {
            return updatedServices.map(service => ({
              ...service,
              isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
              isNew: !prevServices.some(s => s.id === service.id)
            }));
          });
          if (selectedSubItem === deleteConfirmation.id) {
            setSelectedSubItem(null);
          }
        }
      }
    } catch (error) {
      console.error('Failed to delete:', error);
    }
    setDeleteConfirmation({
      show: false,
      type: null,
      id: null,
      serviceId: null,
    });
  };

  const handleAddSubItem = (serviceId: number) => {
    if (activeSublistInput === serviceId) {
      setActiveSublistInput(null);
    } else {
      setActiveSublistInput(serviceId);
      setNewSubItem("");
    }
  };

  const submitEnvironment = async (serviceId: number) => {
    if (newSubItem.trim()) {
      try {
        await createEnvironment(serviceId, newSubItem, '');
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
        setNewSubItem('');
        setActiveSublistInput(null);
      } catch (error) {
        console.error('Failed to create environment:', error);
      }
    }
  };

  const handleEditEnvironment = async (serviceId: number, envId: number) => {
    const service = services.find((s) => s.id === serviceId);
    const environment = service?.environments.find((e) => e.id === envId);
    if (environment) {
      setEditingSubItem(envId);
      setEditedSubItemName(environment.name);
    }
  };

  const saveEnvironmentEdit = async (serviceId: number, envId: number) => {
    if (editedSubItemName.trim()) {
      try {
        await updateEnvironment(
          serviceId,
          envId,
          editedSubItemName,
          contentMap[envId] || ""
        );
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
        setEditingSubItem(null);
        setEditedSubItemName("");
      } catch (error) {
        console.error('Failed to update environment:', error);
      }
    }
  };

  const saveContent = async () => {
    if (selectedService && selectedSubItem) {
      try {
        await updateEnvironmentContent(
          selectedService,
          selectedSubItem,
          contentMap[selectedSubItem] || ""
        );
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
        setIsEditing(false);
        setShowToast(true);
        
        if (isServiceView) {
          await fetchHistory(selectedService);
        } else {
          await fetchHistory(selectedService, selectedSubItem);
        }
      } catch (error) {
        console.error('Failed to save content:', error);
      }
    }
  };

  const toggleExpand = async (serviceId: number) => {
    setServices(
      services.map((service) => {
        if (service.id === serviceId) {
          return {
            ...service,
            isExpanded: !service.isExpanded,
          };
        }
        return service;
      })
    );
    setSelectedService(serviceId);
    setSelectedSubItem(null);
    setIsServiceView(true);
    setSelectedHistoryItem(null);
    setPreviousVersionContent("");
    setSelectedVersionContent("");
    await fetchHistory(serviceId);
  };

  const handleCreateService = async () => {
    if (newServiceName.trim()) {
      try {
        await createMSAService(newServiceName);
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });
        setNewServiceName("");
      } catch (error) {
        console.error('Failed to create service:', error);
      }
    }
  };

  const handleEnvironmentSelect = async (environmentId: number) => {
    const service = services.find(s => 
      s.environments.some(e => e.id === environmentId)
    );
    if (service) {
      setSelectedService(service.id);
      setSelectedSubItem(environmentId);
      setIsServiceView(false);
      setSelectedHistoryItem(null);
      setPreviousVersionContent("");
      setSelectedVersionContent("");
      await fetchHistory(service.id, environmentId);
    }
  };

  const fetchHistory = async (serviceId: number, environmentId?: number) => {
    try {
      const historyData = environmentId 
        ? await getEnvironmentHistory(environmentId)
        : await getServiceHistory(serviceId);
      setHistory(historyData);
    } catch (error) {
      console.error('Failed to fetch history:', error);
      setHistory([]);
    }
  };

  const calculateLinePositions = (text: string) => {
    if (!text) return [];
    const positions: number[] = [];
    let lineCount = 0;
    
    text.split('\n').forEach((_,) => {
      positions.push(lineCount);
      lineCount += 1;
    });
    
    return positions;
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newContent = e.target.value;
    setContentMap((prev) => ({
      ...prev,
      [selectedSubItem!]: newContent,
    }));
    setLinePositions(calculateLinePositions(newContent));
  };

  useEffect(() => {
    if (selectedSubItem && contentMap[selectedSubItem]) {
      setLinePositions(calculateLinePositions(contentMap[selectedSubItem]));
    } else {
      setLinePositions([]);
    }
  }, [selectedSubItem, contentMap]);

  useEffect(() => {
    const handleResize = () => {
      if (selectedSubItem && contentMap[selectedSubItem]) {
        setLinePositions(calculateLinePositions(contentMap[selectedSubItem]));
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [selectedSubItem, contentMap]);

  const handleHistoryItemClick = async (historyItem: HistoryItem) => {
    setSelectedHistoryItem(historyItem);
    
    try {
      const data = await getVersionDiff(historyItem.id);
      
      setPreviousVersionContent(data.previousContent);
      setSelectedVersionContent(data.selectedContent);
    } catch (error) {
      console.error('Failed to fetch diff content:', error);
    }
  };

  const handleEditClick = () => {
    if (isEditing) {
      saveContent();
    } else {
      setSelectedHistoryItem(null);
      setPreviousVersionContent("");
      setSelectedVersionContent("");
      setIsEditing(true);
    }
  };

  const handleRevertVersion = async (historyItem: HistoryItem) => {
    if (selectedService && selectedSubItem) {
      try {
        // API 호출을 통해 선택된 버전의 content를 가져옵니다
        const data = await getVersionContent(historyItem.id);
        
        // 가져온 content로 환경을 업데이트합니다
        await updateEnvironmentContent(
          selectedService,
          selectedSubItem,
          data.content
        );

        // 서비스 목록을 업데이트합니다
        const updatedServices = await getMSAServices();
        setServices(prevServices => {
          return updatedServices.map(service => ({
            ...service,
            isExpanded: prevServices.find(s => s.id === service.id)?.isExpanded || false,
            isNew: !prevServices.some(s => s.id === service.id)
          }));
        });

        // 현재 content를 업데이트합니다
        setContentMap(prev => ({
          ...prev,
          [selectedSubItem]: data.content
        }));

        // history를 새로고침합니다
        if (isServiceView) {
          await fetchHistory(selectedService);
        } else {
          await fetchHistory(selectedService, selectedSubItem);
        }

        setShowToast(true);
      } catch (error) {
        console.error('Failed to revert version:', error);
      }
    }
  };

  const handleDownload = async () => {
    if (!selectedService || !selectedSubItem) return;

    setShowExtensionModal(true);
  };

  const downloadContent = async (extension: string) => {
    try {
      let content = "";
      const service = services.find(s => s.id === selectedService);
      const environment = service?.environments.find(e => e.id === selectedSubItem);
      
      if (!service || !environment || !selectedSubItem) return;

      if (selectedHistoryItem && selectedHistoryItem.id) {
        // 특정 버전이 선택된 경우
        try {
          const data = await getVersionContent(selectedHistoryItem.id);
          content = data.content || "";
          
          if (!content) {
            throw new Error('Content is empty');
          }
        } catch (error) {
          console.error('Failed to fetch version content:', error);
          return;
        }
      } else {
        // HEAD 버전을 다운로드
        content = contentMap[selectedSubItem] || "";
      }

      if (!content.trim()) {
        console.error('Content is empty');
        return;
      }

      // 파일명 생성
      let fileName = "";
      if (selectedHistoryItem) {
        const modifiedDate = new Date(selectedHistoryItem.modifiedAt);
        const formattedDate = modifiedDate.toISOString().split('.')[0].replace(/[:-]/g, '');
        fileName = `${service.name}-${environment.name}-${formattedDate}.${extension}`;
      } else {
        fileName = `${service.name}-${environment.name}.${extension}`;
      }

      // 파일 다운로드
      const blob = new Blob([content], { type: 'text/plain;charset=utf-8' });
      if (blob.size === 0) {
        console.error('Blob is empty');
        return;
      }

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      
      // 클린업
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 100);

      setShowExtensionModal(false);
      setFileExtension("");
    } catch (error) {
      console.error('Failed to download content:', error);
    }
  };

  return (
    <div className="absolute inset-0 flex flex-col bg-white">
      <Navbar setSidebarOpen={setSidebarOpen} />
      <Toast 
        message="저장되었습니다" 
        isVisible={showToast} 
        onClose={() => setShowToast(false)} 
      />

      {deleteConfirmation.show && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">삭제 확인</h3>
            <p className="text-gray-600 mb-6">
              {deleteConfirmation.type === "service"
                ? "이 서비스를 삭제하시겠습니까? 모든 하위 항목도 함께 삭제됩니다."
                : "이 항목을 삭제하시겠습니까?"}
            </p>
            <div className="flex justify-end gap-4">
              <button
                onClick={() =>
                  setDeleteConfirmation({
                    show: false,
                    type: null,
                    id: null,
                    serviceId: null,
                  })
                }
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                취소
              </button>
              <button
                onClick={confirmDelete}
                className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 transition-colors"
              >
                삭제
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity md:hidden z-40 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setSidebarOpen(false)}
      />

      <div
        className={`fixed top-0 left-0 h-full w-[300px] bg-white shadow-xl transform transition-transform duration-300 ease-in-out z-50 md:hidden ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="p-6">
          <button
            onClick={() => setSidebarOpen(false)}
            className="absolute top-4 right-4"
            aria-label="Close menu"
          >
            <X className="w-6 h-6 text-gray-600" />
          </button>

          <div className="mt-8">
            <div className="relative mb-4">
              <input
                type="text"
                placeholder="서비스 검색..."
                className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
              />
              <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
            </div>

            <ul className="space-y-2">
              {services.map((service) => (
                <ServiceItem
                  key={service.id}
                  service={service}
                  selectedService={selectedService}
                  selectedSubItem={selectedSubItem}
                  editingService={editingService}
                  editingSubItem={editingSubItem}
                  editedServiceName={editedServiceName}
                  editedSubItemName={editedSubItemName}
                  activeSublistInput={activeSublistInput}
                  newSubItem={newSubItem}
                  onToggleExpand={toggleExpand}
                  onEditService={handleEditService}
                  onDeleteService={handleDeleteService}
                  onSaveServiceEdit={saveServiceEdit}
                  onEditedServiceNameChange={setEditedServiceName}
                  onAddSubItem={handleAddSubItem}
                  onNewSubItemChange={setNewSubItem}
                  onSubmitEnvironment={submitEnvironment}
                  onEnvironmentSelect={handleEnvironmentSelect}
                  onEnvironmentEdit={handleEditEnvironment}
                  onEnvironmentDelete={handleDeleteEnvironment}
                  onEnvironmentSave={saveEnvironmentEdit}
                  onEditedSubItemNameChange={setEditedSubItemName}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="flex-1 mt-[60px] md:mt-[72px] overflow-auto">
        <div className="max-w-[1440px] mx-auto px-4 md:px-8 py-6">
          <div className="mb-8 hidden md:block">
            <div className="flex gap-4">
              <div className="relative flex-1">
                <input
                  type="text"
                  placeholder="서비스 검색..."
                  className="w-full pl-12 pr-4 py-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-transparent transition-all"
                />
                <SearchIcon className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
              </div>
              <button className="px-6 py-3 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-2 text-sm font-medium whitespace-nowrap">
                <SearchIcon className="w-4 h-4" />
                검색
              </button>
            </div>
          </div>

          <div className="flex flex-col xl:flex-row gap-4 xl:gap-8">
            <div className="flex flex-col flex-1">
              <div className="flex flex-col md:flex-row gap-4 md:gap-8">
                <div className="hidden md:flex md:w-[500px] flex-col">
                  <div className="border border-gray-200 rounded-lg p-4 mb-4 h-[50vh] bg-white">
                    <div className="h-full overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-400">
                      <div className="pr-2">
                        <ul className="space-y-2">
                          {services.map((service) => (
                            <ServiceItem
                              key={service.id}
                              service={service}
                              selectedService={selectedService}
                              selectedSubItem={selectedSubItem}
                              editingService={editingService}
                              editingSubItem={editingSubItem}
                              editedServiceName={editedServiceName}
                              editedSubItemName={editedSubItemName}
                              activeSublistInput={activeSublistInput}
                              newSubItem={newSubItem}
                              onToggleExpand={toggleExpand}
                              onEditService={handleEditService}
                              onDeleteService={handleDeleteService}
                              onSaveServiceEdit={saveServiceEdit}
                              onEditedServiceNameChange={setEditedServiceName}
                              onAddSubItem={handleAddSubItem}
                              onNewSubItemChange={setNewSubItem}
                              onSubmitEnvironment={submitEnvironment}
                              onEnvironmentSelect={handleEnvironmentSelect}
                              onEnvironmentEdit={handleEditEnvironment}
                              onEnvironmentDelete={handleDeleteEnvironment}
                              onEnvironmentSave={saveEnvironmentEdit}
                              onEditedSubItemNameChange={setEditedSubItemName}
                            />
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-2 min-w-[400px]">
                    <input
                      type="text"
                      value={newServiceName}
                      onChange={(e) => setNewServiceName(e.target.value)}
                      placeholder="새 서비스 이름..."
                      className="flex-1 px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500"
                    />
                    <button
                      onClick={handleCreateService}
                      className="px-5 py-3 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg hover:-translate-y-0.5 flex items-center justify-center gap-2 text-sm font-medium"
                    >
                      <PlusIcon className="w-4 h-4" />
                      추가
                    </button>
                  </div>
                </div>

                <div className="w-full flex flex-col">
                  <div className="border border-gray-200 rounded-lg p-4 mb-4 h-[50vh] bg-gray-50">
                    {selectedSubItem ? (
                      selectedHistoryItem ? (
                        <DiffViewer
                          oldContent={previousVersionContent}
                          newContent={selectedVersionContent}
                        />
                      ) : (
                        <ConfigEditor
                          isEditing={isEditing}
                          selectedSubItem={selectedSubItem}
                          contentMap={contentMap}
                          handleTextChange={handleTextChange}
                          linePositions={linePositions}
                        />
                      )
                    ) : (
                      <div className="w-full h-full flex items-center justify-center text-gray-500">
                        {window.innerWidth >= 768
                          ? "하위 항목을 선택해주세요."
                          : "메뉴에서 서비스와 하위 항목을 선택해주세요."}
                      </div>
                    )}
                  </div>
                  <div className="flex gap-4 min-w-[400px]">
                    <button
                      onClick={handleEditClick}
                      className={`flex-1 px-5 py-3 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg transition-all duration-300 shadow-md flex items-center justify-center gap-2 text-sm font-medium ${
                        selectedSubItem && !selectedHistoryItem
                          ? "hover:from-violet-700 hover:to-purple-700 hover:shadow-lg hover:-translate-y-0.5"
                          : "opacity-50 cursor-not-allowed"
                      }`}
                      disabled={!selectedSubItem || !!selectedHistoryItem}
                    >
                      {isEditing ? (
                        <>
                          <SaveIcon className="w-4 h-4" />
                          저장
                        </>
                      ) : (
                        <>
                          <Edit className="w-4 h-4" />
                          수정
                        </>
                      )}
                    </button>
                    <button 
                      onClick={handleDownload}
                      disabled={!selectedSubItem}
                      className={`px-5 py-3 bg-gradient-to-r from-gray-500 to-gray-600 text-white rounded-lg transition-all duration-300 shadow-md flex items-center justify-center gap-2 text-sm font-medium ${
                        selectedSubItem ? 'hover:from-gray-600 hover:to-gray-700 hover:shadow-lg hover:-translate-y-0.5' : 'opacity-50 cursor-not-allowed'
                      }`}
                    >
                      <Download className="w-4 h-4" />
                      다운로드
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {(selectedService || selectedSubItem) && (
              <div className="xl:w-[350px] w-full overflow-y-auto max-h-[50vh]">
                <HistoryView 
                  history={history}
                  isServiceView={isServiceView}
                  onHistoryItemClick={handleHistoryItemClick}
                  selectedHistoryItem={selectedHistoryItem}
                  onRevertVersion={handleRevertVersion}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Extension Input Modal */}
      {showExtensionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-4">파일 확장자 입력</h3>
            <input
              type="text"
              value={fileExtension}
              onChange={(e) => setFileExtension(e.target.value)}
              placeholder="확장자를 입력하세요 (예: yml, json)"
              className="w-full px-4 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 mb-4"
            />
            <div className="flex justify-end gap-4">
              <button
                onClick={() => {
                  setShowExtensionModal(false);
                  setFileExtension("");
                }}
                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded-lg transition-colors"
              >
                취소
              </button>
              <button
                onClick={() => downloadContent(fileExtension)}
                disabled={!fileExtension.trim()}
                className={`px-4 py-2 bg-violet-500 text-white rounded-lg transition-colors ${
                  fileExtension.trim() ? 'hover:bg-violet-600' : 'opacity-50 cursor-not-allowed'
                }`}
              >
                다운로드
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
  
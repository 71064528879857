import { ChevronDown, ChevronRight, Check, Edit, Trash2, PlusIcon } from "lucide-react";
import { EnvironmentItem } from "./EnvironmentItem";

interface Environment {
  id: number;
  name: string;
  content: string;
}

interface Service {
  id: number;
  name: string;
  environments: Environment[];
  isExpanded: boolean;
  isNew?: boolean;
}

interface ServiceItemProps {
  service: Service;
  selectedService: number | null;
  selectedSubItem: number | null;
  editingService: number | null;
  editingSubItem: number | null;
  editedServiceName: string;
  editedSubItemName: string;
  activeSublistInput: number | null;
  newSubItem: string;
  onToggleExpand: (serviceId: number) => void;
  onEditService: (serviceId: number) => void;
  onDeleteService: (serviceId: number) => void;
  onSaveServiceEdit: (serviceId: number) => void;
  onEditedServiceNameChange: (name: string) => void;
  onAddSubItem: (serviceId: number) => void;
  onNewSubItemChange: (name: string) => void;
  onSubmitEnvironment: (serviceId: number) => void;
  onEnvironmentSelect: (environmentId: number) => void;
  onEnvironmentEdit: (serviceId: number, environmentId: number) => void;
  onEnvironmentDelete: (serviceId: number, environmentId: number) => void;
  onEnvironmentSave: (serviceId: number, environmentId: number) => void;
  onEditedSubItemNameChange: (name: string) => void;
}

export const ServiceItem = ({
  service,
  selectedService,
  selectedSubItem,
  editingService,
  editingSubItem,
  editedServiceName,
  editedSubItemName,
  activeSublistInput,
  newSubItem,
  onToggleExpand,
  onEditService,
  onDeleteService,
  onSaveServiceEdit,
  onEditedServiceNameChange,
  onAddSubItem,
  onNewSubItemChange,
  onSubmitEnvironment,
  onEnvironmentSelect,
  onEnvironmentEdit,
  onEnvironmentDelete,
  onEnvironmentSave,
  onEditedSubItemNameChange,
}: ServiceItemProps) => {
  return (
    <li key={service.id} className={`space-y-2 ${service.isNew ? "animate-slide-down" : ""}`}>
      <div className="flex items-center">
        <div
          className={`flex-1 flex items-center px-4 py-3 ${
            service.id === selectedService ? "bg-violet-50 text-violet-700" : "hover:bg-gray-50"
          } rounded-lg cursor-pointer transition-colors`}
          onClick={() => onToggleExpand(service.id)}
        >
          {service.environments.length > 0 &&
            (service.isExpanded ? (
              <ChevronDown className="w-4 h-4 mr-2" />
            ) : (
              <ChevronRight className="w-4 h-4 mr-2" />
            ))}
          {editingService === service.id ? (
            <div className="flex-1 flex items-center gap-2">
              <input
                type="text"
                value={editedServiceName}
                onChange={(e) => onEditedServiceNameChange(e.target.value)}
                className="flex-1 px-2 py-1 rounded border focus:outline-none focus:ring-2 focus:ring-violet-500"
                autoFocus
                onClick={(e) => e.stopPropagation()}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onSaveServiceEdit(service.id);
                }}
                className="p-1 hover:bg-violet-100 rounded-full transition-colors"
              >
                <Check className="w-4 h-4 text-violet-600" />
              </button>
            </div>
          ) : (
            service.name
          )}
        </div>
        <div className="flex items-center gap-1">
          <button
            onClick={(e) => {
              e.stopPropagation();
              onEditService(service.id);
            }}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
            aria-label="Edit service"
          >
            <Edit className="w-4 h-4 text-gray-600" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onDeleteService(service.id);
            }}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
            aria-label="Delete service"
          >
            <Trash2 className="w-4 h-4 text-red-500" />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAddSubItem(service.id);
            }}
            className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200"
            aria-label="Add sub-item"
          >
            <PlusIcon className="w-4 h-4 text-gray-600" />
          </button>
        </div>
      </div>

      {activeSublistInput === service.id && (
        <div className="ml-6 flex gap-2 animate-slide-down">
          <input
            type="text"
            value={newSubItem}
            onChange={(e) => onNewSubItemChange(e.target.value)}
            onKeyPress={(e) => e.key === "Enter" && onSubmitEnvironment(service.id)}
            placeholder="환경 추가..."
            className="flex-1 px-3 py-2 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-violet-500 focus:border-transparent"
            autoFocus
          />
          <button
            onClick={() => onSubmitEnvironment(service.id)}
            className="px-3 py-2 bg-violet-600 text-white rounded-lg hover:bg-violet-700 transition-colors"
          >
            추가
          </button>
        </div>
      )}

      {service.isExpanded && service.environments.length > 0 && (
        <ul className="ml-6 space-y-2">
          {service.environments.map((environment, index) => (
            <EnvironmentItem
              key={environment.id}
              environment={environment}
              serviceId={service.id}
              selectedSubItem={selectedSubItem}
              editingSubItem={editingSubItem}
              editedSubItemName={editedSubItemName}
              isNew={index === service.environments.length - 1}
              onSelect={onEnvironmentSelect}
              onEdit={onEnvironmentEdit}
              onDelete={onEnvironmentDelete}
              onSave={onEnvironmentSave}
              onNameChange={onEditedSubItemNameChange}
            />
          ))}
        </ul>
      )}
    </li>
  );
}; 
const apiUrl = import.meta.env.VITE_API_SERVER_URL;

interface VerifyOTPData {
    email: string;
    code: number;
}

interface VerifyOTPResponse {
    success: boolean;
}

export const requestVerifyOTP = async (data: VerifyOTPData): Promise<VerifyOTPResponse> => {
    const response = await fetch(`${apiUrl}/auth/verify`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include"
    });
    const result = await response.json();
    return result;
};
import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import crevLogo from "../../assets/logo.webp";
import { requestLogin } from "../../request/Login.ts";
import { useNavigate } from "react-router-dom";
import {requestVerifyOTP} from "../../request/VerifyOTP.ts";

export default function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [code, setCode] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const loginResult = await requestLogin({
      email,
      password,
    });
    if (!loginResult.isActive) {
      alert("관리자의 승인을 기다려주세요.");
      navigate("/login");
      return;
    }
    if (!loginResult.success) {
      alert("로그인에 실패했습니다. 관리자에게 문의해주세요.");
      navigate("/login");
      return;
    }

    const intCode = parseInt(code);

    const verifyResult = await requestVerifyOTP({
      email,
      code : intCode
    });

    if (!verifyResult.success) {
      alert("OTP 인증에 실패했습니다.");
      navigate("/login");
      return;
    }

    navigate("/home")
  };

  return (
    <div className="min-h-screen w-screen bg-white flex items-center justify-center p-4">
      <div className="w-full max-w-[400px] mx-auto">
        <div className="text-center mb-10">
          <img
            src={crevLogo}
            alt="Crev Logo"
            className="w-12 h-12 mx-auto mb-6"
          />
          <h1 className="text-2xl font-bold text-gray-900">
            Welcome Crev Member!
          </h1>
          <p className="text-gray-500 mt-2">Tools를 사용하려면 로그인해주세요!</p>
        </div>

        <form className="space-y-5" onSubmit={handleSubmit}>
          <div className="space-y-1.5">
            <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Email을 입력해주세요"
            />
          </div>

          <div className="space-y-1.5">
            <div className="relative">
              <input
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="비밀번호를 입력해주세요"
              />
              <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                {showPassword ? <EyeOff size={20}/> : <Eye size={20}/>}
              </button>
            </div>
          </div>

          <div className="space-y-1.5">
            <input
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Code를 입력해주세요"
            />
          </div>

          <button
              type="submit"
              className="w-full bg-purple-600 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors font-medium"
          >
            로그인
          </button>
        </form>

        <div className="text-center mt-8">
          <p className="text-sm text-gray-500">
            계정이 없으신가요?{" "}
            <a href="/register" className="text-purple-600 hover:text-purple-700 font-medium">
              회원가입
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginPage from './components/auth/Login.tsx';
import ContentGrid from './components/landing/ContentGrid.tsx';
import RegisterPage from './components/auth/Register.tsx';
import SwaggerPage from './components/swagger/Swagger.tsx';
import { JiraPage } from './components/jira/Jira.tsx';
import DraggableContentGrid from './components/changing-cards/DraggableContentGrid.tsx';
import MSAConfig from './components/msa-configs/MSAConfig.tsx';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<ContentGrid />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/swagger" element={<SwaggerPage />} />
        <Route path="/jira" element={<JiraPage />} />
        <Route path="/arrange" element={<DraggableContentGrid />} />
        <Route path="/msa-configs" element={<MSAConfig />} />
      </Routes>
    </Router>
  );
}

export default App;

import { Menu, Users } from "lucide-react";
import crevLogo from "../../assets/logo.webp";
import { useNavigate } from "react-router-dom";

interface NavbarProps {
  setSidebarOpen: (open: boolean) => void;
}

export const Navbar = ({ setSidebarOpen }: NavbarProps) => {
  const navigate = useNavigate();

  const handleGoToTools = () => {
    navigate("/");
  };

  return (
    <nav className="fixed top-0 left-0 right-0 w-full bg-white z-50 border-b border-gray-200">
      <div className="max-w-[1440px] mx-auto flex justify-between items-center py-2 md:py-4 px-4 md:px-8">
        <div className="flex items-center gap-1.5 md:gap-2">
          <button
            className="md:hidden p-1.5"
            onClick={() => setSidebarOpen(true)}
            aria-label="Open menu"
          >
            <Menu className="w-5 h-5 md:w-6 md:h-6 text-gray-600" />
          </button>
          <img
            src={crevLogo}
            alt="Crev Tools Logo"
            className="w-6 h-6 md:w-8 md:h-8"
          />
          <span className="text-lg md:text-xl font-semibold text-gray-800">
            Crev Tools
          </span>
        </div>
        <button onClick={handleGoToTools} className="px-3 md:px-5 py-2 md:py-2.5 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-1.5 md:gap-2 text-xs md:text-sm font-medium">
          <Users className="w-3.5 h-3.5 md:w-4 md:h-4" />
          Tools로 돌아가기
        </button>
      </div>
    </nav>
  );
}; 
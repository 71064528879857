import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Services } from '../../request/GetServices';

interface DraggableContentCardProps extends Services {
  index: number;
}

export const DraggableContentCard: React.FC<DraggableContentCardProps> = ({ index, image, title, tags }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ 
    id: index ,
    animateLayoutChanges: () => true,
    transition: {
      duration: 200,
      easing: 'ease'
    }
});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="group bg-gradient-to-r from-gray-50 to-gray-50 hover:from-violet-50 hover:to-purple-50 rounded-lg overflow-hidden transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg cursor-move border border-gray-100"
    >
      <div className="aspect-video relative">
        <img src={image} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="p-4">
        <h3 className="text-gray-800 text-lg font-semibold mb-2 group-hover:text-violet-700 transition-colors">
          {title}
        </h3>
        <div className="flex flex-wrap gap-2">
          {tags.map((tag, tagIndex) => (
            <span
              key={tagIndex}
              className="px-3 py-1 rounded-full text-xs bg-violet-100 text-violet-700 font-medium transition-all duration-300 group-hover:bg-violet-200"
            >
              {tag}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};
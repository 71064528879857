import { Eye, EyeOff, ChevronDown } from "lucide-react";
import crevLogo from "../../assets/logo.webp";
import React, { useEffect, useState, useRef } from "react";
import { requestRegister } from "../../request/Register.ts";
import { requestSetupOTP } from "../../request/SetupOTP.ts";
import { useNavigate } from "react-router-dom";
import { QRAuth } from "./QRAuth.tsx";

export default function RegisterPage() {
    const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const teams = [
    {
      value: "Backend",
      label: "Backend",
    },
    {
      value: "Frontend",
      label: "Frontend",
    },
    {
      value: "Admin",
      label: "Admin",
    },
  ];

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const registerResult = await requestRegister({
        email,
        password,
        team: selectedTeam,
      });

      if (!registerResult.success) {
        alert("회원가입에 실패했습니다");
        navigate("/login");
      }       
      const userEmail = registerResult.email;
      const setupResult = await requestSetupOTP({ email: userEmail });  
      
      if (setupResult.success && setupResult.qrCodeUrl) {
        setQrCodeUrl(setupResult.qrCodeUrl);
      } else {
        alert("OTP 설정에 실패했습니다");
      }
    } catch (error) {
      console.error("Registration failed:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="min-h-screen w-screen bg-white flex items-center justify-center p-6">
      <div className="w-full max-w-[400px] mx-auto">
        {qrCodeUrl ? (
          <QRAuth qrCodeUrl={qrCodeUrl.toString()} email={email.toString()} />
        ) : (
          <>
            <div className="text-center mb-10">
              <img
                src={crevLogo}
                alt="Crev Logo"
                className="w-12 h-12 mx-auto mb-6"
              />
              <h1 className="text-2xl font-bold text-gray-900">
                Create your account
              </h1>
              <p className="text-gray-500 mt-2">Crev Tools를 시작해보세요!</p>
            </div>

            <form className="space-y-5" onSubmit={handleSubmit}>
              <div className="space-y-1.5">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="Email"
                />
              </div>

              <div className="space-y-1.5">
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="비밀번호"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPassword(!showPassword)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>

              <div className="space-y-1.5">
                <div className="relative">
                  <input
                    type={showPasswordConfirm ? "text" : "password"}
                    className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                    placeholder="비밀번호 확인"
                  />
                  <button
                    type="button"
                    onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600"
                  >
                    {showPasswordConfirm ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </div>
              </div>

              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700">
                  Select your team
                </label>
                <div className="relative" ref={dropdownRef}>
                  <button
                    type="button"
                    onClick={() => setIsOpen(!isOpen)}
                    className="w-full px-4 py-3 bg-gray-50 border border-gray-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-left flex justify-between items-center"
                  >
                    <span
                      className={selectedTeam ? "text-gray-900" : "text-gray-400"}
                    >
                      {selectedTeam
                        ? teams.find((team) => team.value === selectedTeam)?.label
                        : "팀을 선택해주세요"}
                    </span>
                    <ChevronDown
                      className={`text-gray-400 transition-transform duration-200 ${isOpen ? "transform rotate-180" : ""}`}
                      size={20}
                    />
                  </button>
                  {isOpen && (
                    <div className="absolute w-full mt-1 bg-white border border-gray-200 rounded-xl shadow-lg z-10 transition-all duration-200 ease-in-out">
                      <div className="py-1 max-h-60 overflow-auto">
                        {teams.map((team) => (
                          <button
                            key={team.value}
                            type="button"
                            className={`w-full px-4 py-2 text-left hover:bg-purple-50 transition-colors ${selectedTeam === team.value ? "text-purple-600 bg-purple-50" : "text-gray-700"}`}
                            onClick={() => {
                              setSelectedTeam(team.value);
                              setIsOpen(false);
                            }}
                          >
                            {team.label}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="w-full bg-purple-600 text-white py-3 rounded-xl hover:bg-purple-700 transition-colors font-medium"
              >
                회원가입
              </button>
            </form>

            <div className="text-center mt-8">
              <p className="text-sm text-gray-500">
                이미 계정이 있으신가요?{" "}
                <a href="/login" className="text-purple-600 hover:text-purple-700 font-medium">
                  로그인
                </a>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const apiUrl = import.meta.env.VITE_API_SERVER_URL;

export const getJiraUrl = async (): Promise<string> => {
  const response = await fetch(`${apiUrl}/jira`);
  const result = await response.json();
  if (!result.success) {
    return '';
  }
  return result.jiraUrl;
};

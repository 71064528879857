import { Check, Edit, Trash2 } from "lucide-react";

interface Environment {
  id: number;
  name: string;
  content: string;
}

interface EnvironmentItemProps {
  environment: Environment;
  serviceId: number;
  selectedSubItem: number | null;
  editingSubItem: number | null;
  editedSubItemName: string;
  isNew: boolean;
  onSelect: (environmentId: number) => void;
  onEdit: (serviceId: number, environmentId: number) => void;
  onDelete: (serviceId: number, environmentId: number) => void;
  onSave: (serviceId: number, environmentId: number) => void;
  onNameChange: (name: string) => void;
}

export const EnvironmentItem = ({
  environment,
  serviceId,
  selectedSubItem,
  editingSubItem,
  editedSubItemName,
  isNew,
  onSelect,
  onEdit,
  onDelete,
  onSave,
  onNameChange,
}: EnvironmentItemProps) => {
  return (
    <li
      className={`flex items-center justify-between px-4 py-2 bg-gray-50 rounded-lg text-sm text-gray-700 hover:bg-gray-100 transition-colors ${
        selectedSubItem === environment.id ? "ring-2 ring-violet-500" : ""
      } ${isNew ? "animate-slide-down" : ""}`}
      onClick={() => onSelect(environment.id)}
    >
      {editingSubItem === environment.id ? (
        <div className="flex-1 flex items-center gap-2">
          <input
            type="text"
            value={editedSubItemName}
            onChange={(e) => onNameChange(e.target.value)}
            className="flex-1 px-2 py-1 rounded border focus:outline-none focus:ring-2 focus:ring-violet-500"
            autoFocus
          />
          <button
            onClick={(e) => {
              e.stopPropagation();
              onSave(serviceId, environment.id);
            }}
            className="p-1 hover:bg-violet-100 rounded-full transition-colors"
          >
            <Check className="w-4 h-4 text-violet-600" />
          </button>
        </div>
      ) : (
        <>
          {environment.name}
          <div className="flex items-center gap-1">
            <button
              onClick={(e) => {
                e.stopPropagation();
                onEdit(serviceId, environment.id);
              }}
              className="p-1 hover:bg-violet-100 rounded-full transition-all duration-200"
            >
              <Edit className="w-4 h-4 text-violet-600" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(serviceId, environment.id);
              }}
              className="p-1 hover:bg-violet-100 rounded-full transition-all duration-200"
            >
              <Trash2 className="w-4 h-4 text-red-500" />
            </button>
          </div>
        </>
      )}
    </li>
  );
}; 
import React from 'react';
import { useNavigate } from 'react-router-dom';
interface ContentCardProps {
  keydownProps: boolean;
  image: string;
  title: string;
  tags: string[];
  url: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({ keydownProps, image, title, tags, url }) => {

  const navigate = useNavigate();

  const handleClick = () => {
    if (keydownProps) {
      window.open(url, '_blank', 'noopener,noreferrer');
    } else {
      navigate(url);
    }
  };

    return (
        <div onClick={handleClick} className="group bg-gradient-to-r from-gray-50 to-gray-50 hover:from-violet-50 hover:to-purple-50 rounded-lg overflow-hidden transition-all duration-300 hover:-translate-y-0.5 hover:shadow-lg cursor-pointer border border-gray-100">
          <div className="aspect-video relative">
            <img src={image} alt={title} className="w-full h-full object-cover" />
          </div>
          <div className="p-4">
            <h3 className="text-gray-800 text-lg font-semibold mb-2 group-hover:text-violet-700 transition-colors">
              {title}
            </h3>
            <div className="flex flex-wrap gap-2">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="px-3 py-1 rounded-full text-xs bg-violet-100 text-violet-700 font-medium transition-all duration-300 group-hover:bg-violet-200"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
        </div>
      );
};
const apiUrl = import.meta.env.VITE_API_SERVER_URL;

interface LoginData {
    email: string;
    password: string;
}

interface LoginResponse {
    success: boolean;
    email: string;
    isActive: boolean;
}

export const requestLogin = async (data: LoginData): Promise<LoginResponse> => {
    const response = await fetch(`${apiUrl}/auth/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
};
import {ContentCard} from "./ContentCard.tsx";
import crevLogo from "../../assets/logo.webp";
import {useCallback, useEffect, useState} from "react";
import {requestGetServices, Services} from "../../request/GetServices.ts";
import {useNavigate} from "react-router-dom";

export default function ContentGrid() {
  const [services, setServices] = useState<Services[]>([]);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const navigate = useNavigate();

  const handleKeyDown = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Control' || e.key === 'Meta') {
      setIsCtrlPressed(true);
    }
  }, []);

  const handleKeyUp = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Control' || e.key === 'Meta') {
      setIsCtrlPressed(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    const fetchServices = async () => {
      const services = await requestGetServices();
      setServices(services);
    };
    fetchServices();
  }, []);

  const handleChangeOrderClick = () => {
    navigate('/arrange');
  }

  return (
    <main className="w-full min-h-screen bg-white pt-[72px]">
    <nav className="fixed top-0 left-0 right-0 w-full bg-white z-50 border-b border-gray-200">
      <div className="max-w-[3840px] mx-auto flex justify-between items-center py-4 px-8">
        <div className="flex items-center gap-2">
          <img src={crevLogo} alt="Crev Tools Logo" className="w-8 h-8" />
          <span className="text-xl font-semibold text-gray-800">
            Crev Tools
          </span>
        </div>
        <button onClick={handleChangeOrderClick} className="px-5 py-2.5 bg-gradient-to-r from-violet-600 to-purple-600 text-white rounded-lg hover:from-violet-700 hover:to-purple-700 transition-all duration-300 shadow-md hover:shadow-lg hover:-translate-y-0.5 flex items-center gap-2 text-sm font-medium">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          카드 순서 변경
        </button>
      </div>
    </nav>

    <div className="max-w-[3840px] mx-auto flex flex-col items-center w-full px-4 sm:px-6 lg:px-8 2xl:px-16">
      <h1 className="text-xl text-gray-600 mb-8 mt-8">
        ⚒️ 필요한 도구를 선택하세요 ⚒️
      </h1>

      <div className="w-full max-w-[3840px]">
        <div className="grid grid-cols-1 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 2xl:grid-cols-8 gap-4 md:gap-6 justify-items-center">
          {services.map((service, index) => (
            <ContentCard
              keydownProps={isCtrlPressed}
              key={index}
              image={service.image}
              title={service.title}
              tags={service.tags}
              url={service.url}
            />
          ))}
        </div>
      </div>
    </div>
  </main>
  );
}

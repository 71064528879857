const apiUrl = import.meta.env.VITE_API_SERVER_URL;

export interface PatchServiceOrderRequest {
  title: string;
  order: number;
}

export const patchServiceOrder = async (order: PatchServiceOrderRequest[]): Promise<boolean> => {
  const response = await fetch(`${apiUrl}/services/order`, {
    method: 'PATCH',
    headers: {
        'Content-Type': 'application/json',
    },
    credentials: 'include',  // 쿠키 포함
    body: JSON.stringify({ order }),
  });
  const result = await response.json();
  return result.success;
};

const apiUrl = import.meta.env.VITE_API_SERVER_URL;

interface RegisterData {
    email: string;
    password: string;
    team: string;
}

interface RegisterResponse {
    success: boolean;
    email: string;
}

export const requestRegister = async (data: RegisterData): Promise<RegisterResponse> => {
    const response = await fetch(`${apiUrl}/auth/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    const result = await response.json();
    return result;
    // return {
    //     success: true,
    //     email: 'dummy@email.com'
    // };
};